<script lang="ts" setup>
import { DialogTitle } from '@headlessui/vue'
import { ModalTypes } from '~/components/infrastructure/modal/types'

defineOptions({
  inheritAttrs: false,
})

const { onClose, type } = useModalContext()

const isAdaptive = computed(() => type.value === ModalTypes.ADAPTIVE)
</script>

<template>
  <div
    v-bind="$attrs"
    class="relative flex w-full transform bg-white text-left align-middle transition-all md:shadow-xl"
    :class="[
      isAdaptive ? 'px-5 py-10 md:rounded-[40px] md:p-10' : 'rounded-[40px] px-4 py-6 md:p-10',
    ]"
  >
    <ButtonBack v-if="isAdaptive" class="mb-5 md:hidden" @click="onClose" />
    <button class="modal-close-btn" :class="[{ 'hidden md:block': isAdaptive }]" @click="onClose">
      <Icon name="mdi:close" class="text-[28px] text-white" />
    </button>

    <DialogTitle as="h3">
      <slot name="title" />
    </DialogTitle>

    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-close-btn {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #808080;
  border-radius: 31px;
  width: 34px;
  height: 34px;
  opacity: 0.8;
  transition: all 0.4s;

  &:hover {
    opacity: 1;
  }

  @screen md {
    display: block;
    right: -66px;
    top: 0;
    width: 48px;
    height: 48px;
  }
}
</style>
